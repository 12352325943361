import Request from './request'

export default class BotPitAIService {
  static async getBotConfigFind(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'BotConfig/userFind',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, error, message })
        }
      })
    })
  }
}

import React from 'react'
import { IconIconCrypton } from 'assets/icons/iconAdvanced'
export const MIN_STAKING = 10000

export const WALLET_RECORD_TYPE = {
  ADMIN_ADJUST_INCREASE: 'ADMIN_ADJUST_INCREASE',
  ADMIN_ADJUST_DECREASE: 'ADMIN_ADJUST_DECREASE',
  SAVING: 'SAVING'
}
export const STACKING_ACTIVITY_STATUS = {
  NEW: 0,
  STAKING: 10,
  CANCELED: 20,
  COMPLETED: 30
}

export const STAKING_ACTIVITY_STATUS_STRING = {
  [STACKING_ACTIVITY_STATUS.NEW]: 'staking_new',
  [STACKING_ACTIVITY_STATUS.STAKING]: 'staking_staking',
  [STACKING_ACTIVITY_STATUS.CANCELED]: 'staking_cancel',
  [STACKING_ACTIVITY_STATUS.COMPLETED]: 'staking_completed'
}
export const BONUS_WALLET_LIST = [
  {
    id: 1,
    value: 'PIT',
    label: (
      <div style={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
        <img style={{ width: '32px', height: '32px' }} src="/logo.png" alt="" />
        <div>PIT</div>
      </div>
    )
  },
  {
    id: 2,
    value: 'USDT',
    label: (
      <div style={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
        <div>
          <IconIconCrypton width={32} height={32} />
        </div>
        <div>USDT</div>
      </div>
    )
  }
]
export const formatDisplayNumber = (value) => {
  if (value) {
    const number = value.toLocaleString()
    return number
  }
  return value
}

export const MESSAGE_TYPE = {
  SYSTEM_SUCCESS_MESSAGE: 1, // gửi tin nhắn thành công
  SYSTEM_FAILED_MESSAGE: 2, // gửi tin nhắn thất bại
  SYSTEM_NORMAL_MESSAGE: 3 // thông báo chung của user
}

export const VALUE_REGISTER = {
  MIN: 6,
  MAX: 20
}

export const MESSAGE_SUB_TYPE = {
  DEPOSIT_MESSAGE: 1, // nạp usdt / banking
  WITHDRAW_MESSAGE: 2, // rút usdt / banking
  USER_IDENTITY_MESSAGE: 3, // thông báo của user
  SYSTEM_MESSAGE: 4, // thông báo hệ thống
  TRANS_WALLET: 5,
  TRANS_COIN: 6,
  TRANS_MACHINE: 7, // chuyển nhượng, nhận
  DETAIL_MACHINE: 8, // chi tiết máy thuê, tặng, thu hồi , hết hạn máy
  LEADER_BROAD: 9, //bảng xếp hạng
  LEVEL: 10, //cấp độ
  APY: 11, //lãi qua đêm
  STACKING: 12, // thông báo staking
  DEPOSIT_MESSAGE_PIT: 13, // nạp pit
  WITHDRAW_MESSAGE_PIT: 14 // rút pit
}

export const SYSTEM_POSTS_CATEGORIES = {
  SYSTEM_INTRODUCTION: 1, //Hệ thống > Giới thiệu
  SYSTEM_INTRODUCTION_INTRODUCTION: 2, //Hệ thống > Giới thiệu (mục con)
  SYSTEM_INTRODUCTION_TERMOFUSAGE: 3, //Hệ thống > Điều khoản và điều kiện
  SYSTEM_INTRODUCTION_SECUREPOLICY: 4, //Hệ thống > Chính sách bảo mật
  SYSTEM_FQA: 10, //Hệ thống > Vấn đề thường gặp
  POST_CATEGORIES_PROMOTION: 20, //Hệ thống > Vấn đề thường gặp
  SYSTEM_BROADCAST_NOTIFICATION: 50, //Hệ thống > Thông báo hệ thống
  EXPERT_FQA: 100, //Chuyên gia > Những Vấn đề thường gặp
  INSURANCE_SUMMARY: 150, //Chuyên gia > Những Vấn đề thường gặp
  POLYCI_INSURANCE: 152, //Chuyên gia > Những Vấn đề thường gặp
}

export const BANNER_CATEGORIES = {
  HOMEPAGE: 1,
  DOWNLOAD_APP: 2,
  COPY_BET_HOME: 3,
  INSURANCE_POLICY: 4,
  ADVERTISEMENT_HOME_PAGE: 100,

}

export const WALLET_TYPE = {
  USDT: 'USDTWallet',
  BONUS_SALE: 'BonusSaleWallet', // ví hoa hồng thuê máy
  BONUS_BET: 'BonusBetWallet', // ví hoa hồng khai thác
  SAVING: 'SAVINGWallet', // Ví qua đêm
  POINT: 'PointWallet', // ví chính
  CRYPTO: 'CryptoWallet', // ví PIT
  STAKING: 'SAVING_WALLET', // Ví staking
  ENVIRONMENT: 'EnvironmentWallet', // Ví môi trường
  EXPERT_LEAD_WALLET: 'EXPERT_LEAD_WALLET', // Ví lợi nhuận sao chép lệnh (Ví nhận lợi nhuận sao chép lệnh của hội viên )
  EXPERT_FUND_WALLET: 'EXPERT_FUND_WALLET', // Ví quỹ sao chép lệnh (Ví chuyên gia ký quỹ)
  USER_PLAY_WALLET: 'USER_PLAY_WALLET', // Ví tiền chơi sao chép lệnh (Ví hội viên ký quỹ)
}

export const PAYMENT_UNIT = {
  // đơn vị tiền tệ
  GBP: 'GBP', //Bảng Anh
  KRW: 'KRW', //Hàn Quốc
  THB: 'THB', //Bath Thái
  MYR: 'MYR', //Ringgit Malaysia
  RMB: 'RMB', //Nhân dân tệ
  VND: 'VND', //Việt Nam
  IDR: 'IDR', //Đồng rupiah Indonesia
  PHP: 'PHP', //Philippine peso
  USDT: 'USDT',
  PIT: 'PIT'
}

export const MAX_PACKAGE_CURRENT_STAGE = 6
export const MAX_LOGIN_FAILURE = 5


export const GAME_INFO_CATEGORY_ID = {
  CASINO: 400, // casino
  SPORT: 900, //Thể thao
  LOTTERY: 200, // game xổ số
  CRYPTO: 800, // crypto
  DA_GA: 500, // đá gà
  MARBLE: 1000, //Game Bi
  GAMBLING: 100, // game bài
  BAN_CA: 700, // bắn cá
  UP_DOWN: 300, // game chẵn lẻ tài xỉu
  NO_HU: 600, // nổ hũ
}

export const categoryTranslationMap = {
  [GAME_INFO_CATEGORY_ID.CASINO]: 'Live_casino',
  [GAME_INFO_CATEGORY_ID.SPORT]: 'Sport',
  [GAME_INFO_CATEGORY_ID.LOTTERY]: 'Lottery',
  [GAME_INFO_CATEGORY_ID.CRYPTO]: 'Crypto',
  [GAME_INFO_CATEGORY_ID.DA_GA]: 'Gamechicken',
  [GAME_INFO_CATEGORY_ID.MARBLE]: 'Marbles',
  [GAME_INFO_CATEGORY_ID.GAMBLING]: 'Gambling',
  [GAME_INFO_CATEGORY_ID.BAN_CA]: 'Shoot_Fish',
  [GAME_INFO_CATEGORY_ID.UP_DOWN]: 'UP_DOWN',
  [GAME_INFO_CATEGORY_ID.NO_HU]: 'Jackpot',
};

export const USER_FOLLOWER_INVESTMENT_TYPE = {
  FIXED_AMOUNT: 'FIXED_AMOUNT', //số tiền cố định
  FIXED_RATIO: 'FIXED_RATIO', //tỷ lệ cố định
}

export const USER_EXPERT_STATUS = {
  ACTIVE: 'ACTIVE', // đang hoạt động
  PAUSED: 'PAUSED', // đã dừng
  LOCKED: 'LOCKED', // đã khoá
  WAITING_REVIEW: 'WAITING_REVIEW', // Đợi xét duyệt

}

export const USER_EXPERT_MODE = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
}

export const GOLBAL_UNIT = {
  VND: 'VND'
}

export const DEPOSIT_TRX_STATUS = {
  NEW: 'New',
  WAITING: 'Waiting',
  PENDING: 'Pending',
  COMPLETED: 'Completed',
  DELETED: 'Deleted',
  CANCELED: 'Canceled',
}

export const WITHDRAW_TRX_STATUS = {
  NEW: 'New',
  WAITING: 'Waiting',
  PENDING: 'Pending',
  COMPLETED: 'Completed',
  DELETED: 'Deleted',
  CANCELED: 'Canceled',
}

export const OTP_TYPE = {
  FORGOT_PASSWORD: 1,
  FORGOT_SECONDARY_PASSWORD: 2,
  ADD_PAYMENT_METHOD_BANK: 3,
  ADD_PAYMENT_METHOD_CRYPTO: 4
}

export const BET_RECORD_OPTION = [
  { label: "manual_bet", value: 1 },
  { label: "expert_bet", value: 2 },
  { label: "follow_bet_order", value: 3 },
  { label: "Bot_PitAI", value: 4 },
];

export const REIMBURSEMENT_POINTS = 2 //2%

export const REFUND_TRX_CATEGORY = {
  GAME_PLAY_REFUND: 100, // hoàn trả cá nhân
  SERVICE_PACKAGE_REFUND: 200, //hoàn trả bảo hiểm
}

export const REFUND_TRX_STATUS = {
  NEW: 'New',
  WAITING: 'Waiting',
  PENDING: 'Pending',
  COMPLETED: 'Completed',
  DELETED: 'Deleted',
  CANCELED: 'Canceled',
  // VALIDATING_PACKAGE_REFUND: 'VALIDATING_PACKAGE_REFUND', //trường hợp đang validate hoàn trả bảo hiểm
}


export const GAME_INFOR_CURRENCY = {
  VND: 'VND',
  USD: 'USD',
}
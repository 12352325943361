import { LocalStorageManager } from "helper/localStorage"

export const HOT = "HOT"

// **  Initial State
const initialState = {
  listCategory: [],
  listCategoryMiniGame: [],
  listGame: [],
  categoryActive: HOT,
  gameHotLayout: 1,
  listGameHotInfo: []
}

const gameInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GAME_INFO':
      return {
        ...state,
        listGame: action.data
      }
    case 'GAME_CATEGORY':
      return {
        ...state,
        listCategory: action.data
      }
    case 'GAME_CATEGORY_MINI_GAME':
      return {
        ...state,
        listCategoryMiniGame: action.data
      }
    case 'GAME_CATEGORY_ACTIVE':
      return {
        ...state,
        categoryActive: action.data
      }
    case 'GAME_HOT_LAYOUT':
      return {
        ...state,
        gameHotLayout: action.data
      }
    case 'GAME_HOT_INFO':
      return {
        ...state,
        listGameHotInfo: action.data
      }
    default:
      return state
  }
}

export default gameInfoReducer

const initialState = {
  wheelGameSetting: {}
}

const wheelLuckyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'WHEEL_GAME_SETTING':
      return {
        ...state,
        wheelGameSetting: action.data
      }
    default:
      return state
  }
}

export default wheelLuckyReducer

const initialState = {
    botPitAISetting: {}
  }
  
  const botPitAIReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'BOT_PITAI_SETTING':
        return {
          ...state,
          botPitAISetting: action.data
        }
      default:
        return state
    }
  }
  
  export default botPitAIReducer
  
import { combineReducers } from 'redux'
import app from './app'
import member from './member'
import gameInfo from './gameInfo'
import loadingResource from './loadingResource'
import wheelLuckyReducer from './wheelLucky'
import botPitAIReducer from './botPitAI'

const rootReducer = combineReducers({
  member,
  app,
  gameInfo,
  loadingResource,
  wheelLuckyReducer,
  botPitAIReducer
})

export default rootReducer

import { LocalStorageManager } from 'helper/localStorage'
import { useMemo } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'

export default function useActionsCheckIsOpenIframeGame() {
  const path = window.location.origin

  const isOpenIframeGame = useMemo(() => {
    const isWebviewApp = LocalStorageManager.getItem('isWebviewApp') === '1'
    const isMobile = path.includes('mobile') || path.includes('webview') || isWebviewApp

    if ((isAndroid && isMobile) || (isIOS && isMobile)) {
      return true
    }
    return false
  }, [path])

  return {
    isOpenIframeGame
  }
}
